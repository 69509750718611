import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface TCreateIntegrationsPayload {
  companyId: string
  type: string
  token?: string

  clientId?: string
  clientSecret?: string
  refreshToken?: string

  login?: string
  password?: string
}

export interface IIntegrationEntity {
  id: string
  createdAt: string
  isActive: boolean
  companyId: string
  type: string
  token?: string
  clientId?: string
  clientSecret?: string
  refreshToken?: string
  login?: string
  password?: string
}

export class IntegrationApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public show = async (companyId: string): Promise<Result<IIntegrationEntity[]>> => {
    try {
      const respose = await this.service.get(`/integrations/${companyId}`)
      return Result.ok(respose.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public create = async (data: TCreateIntegrationsPayload): Promise<Result<void>> => {
    try {
      await this.service.post('/setup/integration', data)
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
