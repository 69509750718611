import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUserEntity } from './service-user'

export interface IRolesAndAreasResponse {
  roles:
  Array<{
    id: string
    isActive: boolean
    name: string
    normalizedName: string
  }>
  areas:
  Array<{
    id: string

    isActive: boolean
    name: string
    normalizedName: string
  }>
}

export interface TCreateCompanyPayload {
  clientName: string
  collectionName: string
  marketingEnabled: boolean
  salesEnabled: boolean
}

export interface ICompanyEntity {
  id: string
  createdAt: string
  isActive: boolean
  name: string
  normalizedName: string
  marketingEnabled: boolean
  salesEnabled: boolean
  metabaseConfig: {
    id: string
    isActive: true
    companyId: string
    collectionId: number
    collectionName: string
    collectionFolderMarketingId: number
    collectionFolderSalesId: number
    groupSBGeralId: number
    groupSBMarketingId: number
    groupSBSalesId: number
    groupVGeralId: number
    groupVMarketingId: number
    groupVSalesId: number
  }
  users?: IUserEntity[]
  integrations: []
}

export class CompaniesApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public show = async (id: string): Promise<Result<ICompanyEntity>> => {
    try {
      const resp = await this.service.get(`/companies/${id}`)

      return Result.ok(resp.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public list = async (): Promise<Result<ICompanyEntity[]>> => {
    try {
      const resp = await this.service.get('/companies')

      return Result.ok(resp.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public create = async (data: TCreateCompanyPayload): Promise<Result<void>> => {
    try {
      await this.service.post('/setup/company', data)

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public listRolesAndAreas = async (): Promise<Result<IRolesAndAreasResponse>> => {
    try {
      const result = await this.service.get('/companies/roles-and-areas')

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
