/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Flex, Radio, RadioGroup, Text, useTheme } from '@chakra-ui/react'
import React, { FC, useCallback, useState } from 'react'
import { AIICon } from '../../../Assets/IconsComponents/AIIcon'
import { LoaderMessage } from '../LoaderMessage'
import { BiRefresh } from 'react-icons/bi'
import { MdClose, MdOutlineInfo } from 'react-icons/md'
// import { BiLike, BiDislike, disl, BiSolidDislike } from 'react-icons/bi'
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike
} from 'react-icons/ai'

import { TRatingMessageDTO } from '../../../Services/protocols/service-chat'
import { useScreenSizes } from '../../../utils/screenSizes'
import TypeAnimationWithMarkdown from '../../TypeAnimationWithMarkdown'
import { IModeChatType } from '../Interface/IModeChat'
import { HandleSendMessageProps } from '../../../Hooks/useChat'
import { TypeAnimation } from 'react-type-animation'
interface Props {
  message: string
  id: string
  showActions: boolean
  chatId?: string
  index: number
  chatLoading: string
  handleRatingMessage: (data: TRatingMessageDTO) => Promise<void>
  mode: IModeChatType
  lastChild: boolean
  handleSendMessage: (data: HandleSendMessageProps) => Promise<any>

}

interface IRatingProps {
  rate: 'like' | 'dislike' | undefined
  reason?: string
}
const BotMessage: FC<Props> = ({
  message,
  id,
  showActions,
  chatId,
  index,
  chatLoading,
  handleRatingMessage,
  mode,
  lastChild,
  handleSendMessage,
  ...rest
}) => {
  const [rating, setRating] = useState<'like' | 'dislike' | undefined>(
    undefined
  )
  const [finishedAnswer, setFinishedAnswer] = useState(true)
  const { isMobile } = useScreenSizes()
  const [showDislikeOptions, setShowDislikeOptions] = useState(false)
  const theme = useTheme()

  const handleRating = useCallback(async ({ rate, reason }: IRatingProps) => {
    try {
      setRating(rate)
      await handleRatingMessage({ chatId: String(chatId), rating: rate, reason })
    } catch (error) {
      console.log('handleRating error: ', error)
    }
  }, [chatId, handleRatingMessage])

  const handleDislike = useCallback(async (value: string, rate: 'like' | 'dislike' | undefined) => {
    try {
      setRating(rate)
      setShowDislikeOptions(false)
      await handleRatingMessage({ chatId: String(chatId), rating: rate, reason: value })
    } catch (error) {
      console.log('handleRating error: ', error)
    }
  }, [chatId, handleRatingMessage])

  return (
    <Flex
      px={mode === 'popup' ? '24px' : isMobile ? '20px' : '30px'}
      marginBottom={ mode === 'popup' ? '32px' : isMobile ? (index === 0 ? '48px' : '26px') : '6%'}
      marginTop={mode === 'popup' && index === 0 ? '23px' : '0px'}
      {...rest}
    >
      <Flex
        width="49px"
        height="49px"
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
        backgroundColor="gray.900"
      >
        <AIICon />
      </Flex>

      <Box marginLeft="22px" flex={1}>
        <Text
          align="left"
          fontSize="16px"
          fontWeight={800}
          color="gray.900"
          fontFamily="Gantari-Bold"
          marginBottom="5px"
        >
          AI Nalk
        </Text>

        <Flex w="100%">
          <Flex flexDir="column" alignItems="flex-start" flex={1}>
            {
              id === chatLoading ? (
                <Box height={mode === 'fullscreen' ? '90px' : '40px'}>
                  <LoaderMessage />
                </Box>
              ) : (
                <TypeAnimationWithMarkdown message={message} />
              )

              // <TypeAnimation
              //   sequence={[
              //     // Same substring at the start will only be typed out once, initially
              //     message,

              //     () => setFinishedAnswer(true)
              //   ]}
              //   cursor={false}

              //   wrapper="span"
              //   speed={50}
              //   style={{ fontSize: '1rem', lineHeight: '24px', fontWeight: 400, display: 'inline-block' }}
              //   repeat={0}
              // />
            }
            {showActions && chatLoading !== id && (
              <Flex alignItems="center" mt={isMobile ? '8px' : '12px'} justifyContent='space-between' width='100%'>
                {lastChild && <BiRefresh size={26} color={theme.colors.gray.third} cursor='pointer' onClick={() => handleSendMessage({ text: 'fake-message-dont-remove', refresh: true, showInFront: true })} />}

                <Flex alignItems='center' gap={isMobile ? '14px' : '10px'} marginLeft='auto'>
                  {rating === undefined || rating === 'dislike' ? (
                    <AiOutlineLike
                      color={theme.colors.gray.third}
                      onClick={async () => await handleRating({ rate: 'like' })}
                      cursor="pointer"
                    />
                  ) : (
                    <AiFillLike
                      color={theme.colors.gray.third}
                      onClick={async () => await handleRating({ rate: undefined })}
                      cursor="pointer"
                    />
                  )}

                  {rating === undefined || rating === 'like' ? (
                    <AiOutlineDislike
                      color={theme.colors.gray.third}
                      // onClick={async () => await handleRating('dislike')}
                      onClick={() => setShowDislikeOptions(true)}
                      cursor="pointer"
                    />
                  ) : (
                    <AiFillDislike
                      color={theme.colors.gray.third}
                      onClick={async () => await handleRating({ rate: undefined })}
                      cursor="pointer"
                    />
                  )}
                </Flex>
              </Flex>
            )}

            {showDislikeOptions && <Box border='solid 1px #B1C0E0' borderRadius='6px' padding='10px' width='100%' marginTop='10px'>

                <Flex alignItems='center' justifyContent='space-between' width='100%'>
                  <Text
                    align="left"
                    fontSize="16px"
                    fontWeight={700}
                    color="#B1C0E0"
                    fontFamily="Gantari-Bold"
                    marginBottom="5px"
                  >
                    Qual motivo do seu dislike?
                  </Text>

                  <MdClose color='#B1C0E0' size={20} style={{ marginTop: -5, cursor: 'pointer' }} onClick={() => setShowDislikeOptions(false)} />

                </Flex>

                <RadioGroup onChange={async (value) => await handleDislike(value, 'dislike')} value='' name="version" gap='11px'>

                        <Radio value='Lentidão na resposta' width='100%' marginTop='11px' ><Text color='#B1C0E0' fontSize="15px"> Lentidão na resposta </Text></Radio>
                        <Radio value='Resposta errada' width='100%' marginTop='11px' ><Text color='#B1C0E0' fontSize="15px"> Resposta errada</Text></Radio>
                        <Radio value='Outro' width='100%' marginTop='11px' ><Text color='#B1C0E0' fontSize="15px"> Outro</Text></Radio>

                  </RadioGroup>
              </Box>}

          {index === 0 && mode !== 'popup' && <Box mt={isMobile ? '8px' : '12px'} height='16px' />}
          </Flex>
        </Flex>
      </Box>

    </Flex>
  )
}

export { BotMessage }
