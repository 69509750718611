import React, { FC, useCallback, useState } from 'react'
import { Tabs } from '../Tabs'
import { Messages } from '../Messages'
import { Favorites } from '../Favorites'
import { Suggestions } from '../Suggestions'
import { ChatbotInput } from '../../ChatbotInput'
import { HandleSendMessageProps, IMessagesChatProps } from '../../../Hooks/useChat'
import { TRatingMessageDTO } from '../../../Services/protocols/service-chat'
import { Flex, useTheme } from '@chakra-ui/react'
import { MdClose, MdOutlineInfo } from 'react-icons/md'
import { IModeChatType } from '../Interface/IModeChat'
import { TListSuggestionResponse } from '../../../Services/protocols/service-suggestion'
import { TListFavoritesResponse } from '../../../Services/protocols/service-favorites'
import { text } from 'stream/consumers'

interface Props {
  messages: IMessagesChatProps[]
  isLoading: boolean
  handleSendMessage: (data: HandleSendMessageProps) => Promise<void>
  chatLoading: string
  handleRatingMessage: (data: TRatingMessageDTO) => Promise<void>
  mode: IModeChatType
  closeFunction?: () => void
  suggestions: TListSuggestionResponse[]
  favorites: TListFavoritesResponse[]
  handleCreateFavorite: (name: string, messageId: string) => Promise<string | undefined>
  handleDeleteFavorite: (id: string) => Promise<void>
  handleClearChat: () => Promise<void>
  start?: boolean
  handlePreFillMessage: (data: HandleSendMessageProps) => void
  preFillMessage: string
}

const Chat: FC<Props> = ({ chatLoading, handleClearChat, handleRatingMessage, handleSendMessage, isLoading, messages, start, mode, closeFunction, suggestions, favorites, handleCreateFavorite, handleDeleteFavorite, handlePreFillMessage, preFillMessage }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [forceScroll, setForceScroll] = useState(false)
  const theme = useTheme()

  const handleNewMessage = useCallback(async ({ text, favoriteId }: HandleSendMessageProps) => {
    setActiveTab(0)
    await handleSendMessage({ text, favoriteId })
  }, [handleSendMessage])

  const cbForceScroll = () => {
    setForceScroll(true)

    setTimeout(() => {
      setForceScroll(false)
    }, 500)
  }

  return (
        <Flex direction="column" alignItems={mode === 'popup' ? 'flex-start' : 'center' } padding={0} height='100%' >
          {mode === 'popup' && (
            <Flex justifyContent='space-between' padding="24px" borderBottom='solid 1px' borderBottomColor={theme.colors.gray.third} w='100%' marginBottom={activeTab === 0 && mode === 'popup' ? '13px' : '36px'} >
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} mode={mode} />
            <Flex alignItems='center'>
              <MdOutlineInfo fontSize={24}/>
              <MdClose style={{ marginLeft: '12px' }} fontSize={24} onClick={closeFunction} cursor='pointer' />
            </Flex>

          </Flex>
          )}

          {mode === 'fullscreen' && <Tabs activeTab={activeTab} setActiveTab={setActiveTab} mode={mode} />}

          {activeTab === 0 && start && <Messages handleDeleteFavorite={handleDeleteFavorite} handleCreateFavorite={handleCreateFavorite} messages={messages} chatLoading={chatLoading} handleRatingMessage={handleRatingMessage} mode={mode} handleSendMessage={handleSendMessage} handleClearChat={handleClearChat} forceScroll={forceScroll} />}

          {activeTab === 1 && <Suggestions mode={mode} data={suggestions} handleSendMessage={handleNewMessage} handlePreFillMessage={handlePreFillMessage} preFillMessage={preFillMessage} />}

          {activeTab === 2 && <Favorites mode={mode} data={favorites} handleSendMessage={handleNewMessage} handleDeleteFavorite={handleDeleteFavorite} /> }

          {!isLoading && <ChatbotInput handleSendMessage={handleSendMessage} mode={mode} handlePreFillMessage={handlePreFillMessage} preFillMessage={preFillMessage} cleanFunction={() => setActiveTab(0)} cbForceScroll={cbForceScroll} />}
        </Flex>
  )
}

export { Chat }
