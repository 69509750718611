import React, { FC } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  description?: string
  cancelText?: string
  nextText?: string
  cancelScheme?: string
  nextScheme?: string
  thirdButton?: boolean
  thirdButtonText?: string
  thirdButtonFunction?: (data?: any) => void
}
const AlertDialogCustom: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  description,
  cancelText,
  nextText,
  cancelScheme,
  nextScheme,
  thirdButton,
  thirdButtonText,
  thirdButtonFunction
}) => {
  const cancelRef = React.useRef<any>()
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {description || 'Você tem certeza? '}
          </AlertDialogBody>

          <AlertDialogFooter>
            {thirdButton && (
              <Button onClick={thirdButtonFunction}>
                {thirdButtonText || 'Cancelar'}
              </Button>
            )}

            <Button colorScheme={cancelScheme} onClick={cancelFunction}>
              {cancelText || 'Cancelar'}
            </Button>
            <Button
              colorScheme={nextScheme || 'red'}
              onClick={nextFunction}
              ml={3}
            >
              {nextText || 'Sim'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { AlertDialogCustom }
