import { UserApiRemoteService } from '../../Services/protocols/service-user'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ChangeCompanyIdUseUseCase implements IUsecase<string, void> {
  constructor (private readonly repository: UserApiRemoteService) {}

  public async handle (companyId: string): Promise<Result<void>> {
    try {
      await this.repository.changeCompanyId(companyId)

      return Result.ok()
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
