import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputProps as ChakraInputProps,
  CheckboxProps,
  Checkbox as ChakraCheckbox
} from '@chakra-ui/react'
import React, { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { FieldError } from 'react-hook-form'

interface Props extends CheckboxProps {
  name?: string
  label?: string
  errors?: FieldError
  helperText?: string
  leftAddon?: boolean
  leftAddonText?: string
  IconCustom?: any
}

const BaseCheckbox: ForwardRefRenderFunction<any, Props> = (
  {
    name,
    label,
    errors = null,
    helperText,
    leftAddon,
    leftAddonText,
    IconCustom,
    ...rest
  },
  ref
) => {
  const [valueCheck, setValueCheck] = useState()

  return (
    <FormControl id={name} isInvalid={!(errors == null)}>
      {/* { !!label && <FormLabel>{label}</FormLabel> } */}

      <ChakraCheckbox
        name={name}
        id={name}
        size="lg"
        variant="filled"
        colorScheme="blue"
        borderColor="blue"
        // lg={{ '[data-checked]' : { borderColor: 'blue.300'}}}
        focusBorderColor="blue.300"
        ref={ref}
        // value={}
        {...rest}
      >
        {label}
      </ChakraCheckbox>

      {helperText && (
        <FormHelperText color="gray.500">{helperText}</FormHelperText>
      )}
      {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Checkbox = forwardRef(BaseCheckbox)
