import { CompaniesApiRemoteService, ICompanyEntity } from '../../Services/protocols/service-companies'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ListCompanyUseCase implements IUsecase<void, ICompanyEntity[]> {
  constructor (private readonly repository: CompaniesApiRemoteService) {}

  public async handle (): Promise<Result<ICompanyEntity[]>> {
    try {
      const response = await this.repository.list()

      const data = response.getValue()

      if (data === null) {
        return Result.fail(new Error('Clientes não encontrados.'))
      }

      return Result.ok(data)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
