/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, useCallback, useState } from 'react'
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { ArrowLeftIcon } from '../../Assets/IconsComponents/ArrowLeft'
import { useScreenSizes } from '../../utils/screenSizes'
import { Logo } from '../Logo'
import { BackButtonHeader } from './BackButton'
import { MdLogin } from 'react-icons/md'
import { StorageLocalstorage } from '../../Shared/storage/storage-localstorage'
import { useNavigate } from 'react-router-dom'
import { sign } from 'crypto'
import { ModalSignOut } from '../Modals/ModalSignOut'
import { useChat } from '../../Hooks/useChat'

interface IHeaderProps {
  hideSidebar?: boolean
  style?: object
}
const Header: FC<IHeaderProps> = ({ hideSidebar = false, ...rest }) => {
  const [openModal, setOpenModal] = useState(false)
  const { isMobile } = useScreenSizes()
  const { canChangeAiVersion, versionAI, setVersionAI, handleCreateThread, currentCustomerName, userAllowedToChangeCustomer } = useChat()

  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/', { state: { fromFullScreen: true } })
  }

  const handleChangeAI = async (e: number): Promise<void> => {
    console.log('handleChangeAI e: ', e)
    setVersionAI(Number(e))

    await handleCreateThread(String(e))
    setVersionAI(e)
  }

  const isBackofficeRoute = window.location.pathname.includes('/backoffice')
  console.log('isBackofficeRoute: ', isBackofficeRoute)

  return (

    <>
      {openModal && <ModalSignOut onClose={() => setOpenModal(false)} /> }

      { isMobile ? (
          <Flex
            as="header"
            bg="white"
            w="100%"
            position='fixed'
            z-index={999}
            h={!isMobile ? '111px' : '60px'}
            direction={!isMobile ? 'column' : 'row'}
            borderBottom="1px"
            borderColor="#E9E9E9"
            paddingX="20px"
            paddingY={!isMobile ? '10px' : 0}
            {...rest}
          >
            <Flex
              alignItems="center"
              marginTop="10px"
              marginBottom="10px"
              justifyContent="space-between"
              w="100%"
            >
               <Box onClick={handleBack} cursor='pointer'>
                <BackButtonHeader />
             </Box>

              {/* <Flex direction="column" >
                <Logo />
              </Flex> */}

              <Flex position='absolute' right='24px' top='15px'>
                {canChangeAiVersion && <RadioGroup onChange={async (value) => await handleChangeAI(Number(value))} value={versionAI} name="version">
                    <Flex gap="20px" marginRight="20px">
                      <Radio value={1}>Versão 1.0</Radio>
                      <Radio value={2}>Versão 2.0</Radio>
                    </Flex>
                  </RadioGroup>
                }
                <Box right='24px' top='16px' onClick={() => setOpenModal(true)} cursor='pointer'>
                  <MdLogin size='24px' />
                </Box>
              </Flex>

            </Flex>
          </Flex>
        ) : (
          <>
            <Flex position='absolute' right='24px' top='15px' {...rest}>

                {!isBackofficeRoute && userAllowedToChangeCustomer && <Flex mr='20px'>
                  <Button onClick={() => navigate('/backoffice/select-customer?fromChat=true')} marginRight='10px' height='25px' bgColor='#616480'><Text color='#FFFFFF'>Trocar</Text></Button>
                    <Text fontWeight={500}>Logado como cliente: <b>{currentCustomerName?.toUpperCase()}</b></Text>

                  </Flex>
                }
                <Box right='24px' top='16px' onClick={() => setOpenModal(true)} cursor='pointer'>
                  <MdLogin size='24px' />
                </Box>
              </Flex>

              {!hideSidebar && (
                 <Flex
                 backgroundColor="#EFF4FA"
                 width="109px"
                 height="100vh"
                 paddingTop="36px"
                 direction="column"
                 alignItems="center"
                 zIndex={99}
               >
                   <Box onClick={handleBack} cursor='pointer'>
                   <ArrowLeftIcon />
                   </Box>

               </Flex>
              )}

            {/* <div className="bubble-container">

                <Bubble
                  typebot="my-typebot-wdq1jlp"
                  theme={{
                    button: {
                      backgroundColor: '#EFF4FA',
                      customIconSrc:
                        'https://s3.typebot.io/public/workspaces/clxaidtmz00078rwy2bg1ijso/typebots/clxal27wx000lglg1iwdq1jlp/bubble-icon?v=1718126877498'
                    }
                  }}
                />
                </div> */}
          </>
        ) }
    </>
  )
}

export { Header }
