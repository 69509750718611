import styled from 'styled-components'

function getTabColor (isActive: boolean, isDisabled: boolean) {
  if (isDisabled) return 50

  if (isActive) return 800

  return 400
}

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`

export const TabDescription = styled.span`
  font-size: 19px;
  font-weight: 500;
`

export const Tab = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 16px;
  background-color: transparent;

  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? '#2962FF' : 'transparent')};

  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  transition: 0.5s;

  &:hover {
    border-bottom: 2px solid
      ${({ theme, isActive }) =>
        isActive ? '#b3b5c6' : '#2962FF'};
  }

  
`
