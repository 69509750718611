import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface TAuthLoginDTO {
  username: string
  password: string
  macAddress: string
}
export interface TAuthLoginResponse {
  token: string
  refreshToken: string
  metabaseId: string
  metabaseToken: string
  customer: string
  email: string
  needsAcceptTermsOfUse: boolean
  needsAcceptTermsOfPrivacy: boolean
  companyId: string
  companyName: string
}

export interface TCreateMetabaseTokenResponse {
  token: string
}

export interface TAuthRefreshTokenResponse {
  token: string
  refreshToken: string
}

export class AuthenticationApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public login = async (
    data: TAuthLoginDTO
  ): Promise<Result<TAuthLoginResponse>> => {
    try {
      const result = await this.service.post('/session', data)

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public refreshToken = async (
    refreshToken: string
  ): Promise<Result<TAuthRefreshTokenResponse>> => {
    try {
      const result = await this.service.post('/refresh-token', { refreshToken })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public createMetabaseToken = async (): Promise<Result<TCreateMetabaseTokenResponse>> => {
    try {
      const result = await this.service.get('/auth/metabase')
      console.log('createMetabaseToken result.data: ', result.data)
      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
