import { Box, Button, Flex, Text, theme, useToast } from '@chakra-ui/react'
import React, { FC, useState } from 'react'

import { Loader } from '../../../Components/Loader'
import { useScreenSizes } from '../../../utils/screenSizes'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Checkbox } from '../../../Components/Form/Checkbox'
import { Input } from '../../../Components/Form/Input'
import { ToastCustom } from '../../../Components/ToastCustom'
import { CreateCompanyUseCase } from '../../../Usecase/Companies/create-company-usecase'
import { Can } from '../../../Components/Can'

interface Props {
  createCompanyUseCase: CreateCompanyUseCase
}

const attemptToCreateThreadIdLimit = 4

const NewCustomerPage: FC<Props> = ({ createCompanyUseCase }) => {
  const [loading, setLoading] = useState(false)
  const { isMobile } = useScreenSizes()
  const toast = useToast()
  const navigate = useNavigate()

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const handleCreateNewCustomer: SubmitHandler<any> = async (values) => {
    try {
      setLoading(true)
      const { name } = values
      console.log('values', values)

      const payload = {
        clientName: name,
        collectionName: name,
        marketingEnabled: values?.marketingEnabled,
        salesEnabled: values?.salesEnabled

      }
      console.log('payload: ', payload)

      const createResult = await createCompanyUseCase.handle(payload)

      if (createResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={createResult.error?.error ?? 'Erro ao criar o cliente. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Cliente cadastrado' />
      })

      navigate('/backoffice/companies')
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Can page>
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          {loading && <Loader />}
          <Flex flexDirection={isMobile ? 'column' : 'row'} flex={1}>
              <Flex
              as="form"
              w="100%"
              maxWidth='534px'
              height='537px'
              boxShadow='-4px 7px 31px 0px #00000024'
              flexDir="column"
              borderRadius={18}
              onSubmit={handleSubmit(handleCreateNewCustomer)}
              bg="white"
              margin='auto'
              alignItems='center'
              justifyContent='center'

          >

              <Text color="gray.900" fontWeight="700" fontSize="36px" margin={0} fontFamily={theme.fonts.body}>Cadastro de novo cliente</Text>
              <Box marginTop='35px' w='100%' maxWidth='277px'>
              <Input
                  placeholder="Digite o nome do cliente"
                  type="text"
                  label="Nome"
                  labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                  style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                  _placeholder={{ color: 'gray.third' }}
                  {...register('name')}
              />

              <Checkbox
                      label='Marketing'
                      {...register('marketingEnabled')}
              />

                  <Checkbox
                      label='Vendas'
                      {...register('salesEnabled')}
              />

              </Box>

              <Button
                  type="submit"
                  w='278px'
                  height='49px'
                  borderRadius='40px'
                  mt="36px"
                  bg="gray.900"
                  color="gray.800"
                  fontWeight='400'
                  isLoading={formState.isSubmitting}
              >
              Cadastrar
              </Button>
          </Flex>

          </Flex>
      </Flex>
    </Can>
  )
}

export { NewCustomerPage }
