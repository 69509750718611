
import {
  ChatApiRemoteService,
  TRatingMessageDTO
} from '../../Services/protocols/service-chat'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class RatingMessageUseCase
implements IUsecase<TRatingMessageDTO, void> {
  constructor (private readonly repository: ChatApiRemoteService) {}

  public async handle ({ chatId, rating, reason }: TRatingMessageDTO): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.ratingMessage({ chatId, rating, reason })

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao avaliar a mensagem'))
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
