import React from 'react'
import { Container, Tab, TabDescription } from './styles'

interface Props {
  tabs: string[]
  activeTab: string
  disabledTabs?: string[]
  onSelectTab: (tab: string) => void
}

function Tabs ({ tabs, activeTab, disabledTabs = [], onSelectTab }: Props): any {
  function handleSelectTab (tab: string) {
    return () => {
      onSelectTab(tab)
    }
  }

  return (
    <Container>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          isActive={activeTab === tab}
          isDisabled={disabledTabs.includes(tab)}
          onClick={handleSelectTab(tab)}
        >
          <TabDescription>{tab}</TabDescription>
        </Tab>
      ))}
    </Container>
  )
}

export default Tabs
