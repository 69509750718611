import { Box, Button, Flex, theme, useToast, Text } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { Chatbot } from '../../../Components/Chatbot'
import { Header } from '../../../Components/Header'
import { Loader } from '../../../Components/Loader'
import { useScreenSizes } from '../../../utils/screenSizes'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { ModalSignOut } from '../../../Components/Modals/ModalSignOut'
import PrivacyModal from '../../../Components/Modals/PrivacyModal'
import TermsModal from '../../../Components/Modals/TermsModal'
import { ToastCustom } from '../../../Components/ToastCustom'
import { useChat } from '../../../Hooks/useChat'
import { CreateFavoritesUseCase } from '../../../Usecase/Chat/create-favorite-usecase'
import { CreateThreadUseCase } from '../../../Usecase/Chat/create-thread-usecase'
import { DeleteFavoritesUseCase } from '../../../Usecase/Chat/delete-favorite-usecase'
import { ListFavoritesUseCase } from '../../../Usecase/Chat/list-favorites-usecase'
import { ListSuggestionUseCase } from '../../../Usecase/Chat/list-suggestion-usecase'
import { RatingMessageUseCase } from '../../../Usecase/Chat/rating-message-usecase'
import { SendMessageUseCase } from '../../../Usecase/Chat/send-message-usecase'
import { Input } from '../../../Components/Form/Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Checkbox } from '../../../Components/Form/Checkbox'
import { OptionsProps, Select } from '../../../Components/Form/Select'
import { ListCompanyUseCase } from '../../../Usecase/Companies/list-companies-usecase'
import { Can } from '../../../Components/Can'
import { ChangeCompanyIdUseUseCase } from '../../../Usecase/User/change-company-id-usecase'

interface DataProps {
  users: number
  videos: number
}

interface Props {
  listCompanyUseCase: ListCompanyUseCase
  changeCompanyIdUseUseCase: ChangeCompanyIdUseUseCase
}

const SelectCustomerPage: FC<Props> = ({ listCompanyUseCase, changeCompanyIdUseUseCase }) => {
  const [loading, setLoading] = useState(false)
  const { isMobile } = useScreenSizes()
  const [companiesOptions, setCompaniesOptions] = useState<OptionsProps[]>([])
  const toast = useToast()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [searchParams] = useSearchParams()
  const { userAllowedToChangeCustomer, currentCustomerName, companyId } = useChat()

  const selectCustomerForChat = !!searchParams.get('fromChat')

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const changeCompanyId = async (companyId: string): Promise<void> => {
    try {
      setLoading(true)

      const changeResult = await changeCompanyIdUseUseCase.handle(companyId)

      if (changeResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={changeResult.error?.error ?? 'Erro ao trocar o cliente. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Usuário alterado, logar novamente.' />
      })
      navigate('/signin')
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const handleNext: SubmitHandler<any> = async (values) => {
    const { customer } = values

    if (!customer) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description='Selecione o cliente' />
      })
      return
    }

    if (selectCustomerForChat && !userAllowedToChangeCustomer) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description='Você não possui permissão' />
      })
      return
    }

    if (selectCustomerForChat) {
      if (customer !== companyId) {
        // está tentando trocar de usuario
        await changeCompanyId(customer)

        return
      }

      navigate('/')
      return
    }

    navigate(`/backoffice/panel-customer/${String(customer)}`)
  }

  const loadCompanies = async (): Promise<void> => {
    try {
      setLoading(true)

      const listResult = await listCompanyUseCase.handle()

      if (listResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={listResult.error?.error ?? 'Erro ao pegar os clientes. Por favor, tente novamente'} />
        })

        return
      }

      const response = listResult.getValue()

      if (response?.length) {
        const normalize = [
          {
            label: 'Selecione o cliente',
            value: ''
          }
        ]
        response.forEach(i => {
          normalize.push({
            value: i.id,
            label: i.name
          })
        })

        setCompaniesOptions(normalize)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCompanies()
  }, [])

  return (
    <Can page>
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          {loading && <Loader />}
          <Header hideSidebar />
          <Flex flexDirection={isMobile ? 'column' : 'row'} flex={1}>
              <Flex
              as="form"
              w="100%"
              maxWidth='534px'
              height='537px'
              boxShadow='-4px 7px 31px 0px #00000024'
              flexDir="column"
              borderRadius={18}
              onSubmit={handleSubmit(handleNext)}
              bg="white"
              margin='auto'
              alignItems='center'
              justifyContent='center'

          >

              <Text color="gray.900" fontWeight="700" lineHeight='35px' w={selectCustomerForChat ? '70%' : '100%'} fontSize={selectCustomerForChat ? '29px' : '36px'} textAlign='center' margin={0} fontFamily={theme.fonts.body}>{selectCustomerForChat ? 'Você quer acessar a IA com qual cliente?' : 'Seleção de cliente'}</Text>
              <Box marginTop='35px' w='100%' maxWidth='277px'>
                <Select
                options={companiesOptions}
                  {...register('customer')}
                />

              </Box>

              <Button
                  type="submit"
                  w='278px'
                  height='49px'
                  borderRadius='40px'
                  mt="36px"
                  bg="gray.900"
                  color="gray.800"
                  fontWeight='400'
                  isLoading={formState.isSubmitting}
              >
              Avançar
              </Button>
          </Flex>

          </Flex>
      </Flex>
    </Can>
  )
}

export { SelectCustomerPage }
