import FingerprintJS from '@fingerprintjs/fingerprintjs'

const getFingerprint = async (): Promise<string> => {
  // Verifica se o fingerprint já foi salvo no localStorage
  const savedFingerprint = localStorage.getItem('@Nalk:deviceFingerprint')
  if (savedFingerprint) {
    return savedFingerprint
  }

  // Gera um novo fingerprint
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  const newFingerprint = result.visitorId

  // Armazena o fingerprint no localStorage e no estado
  localStorage.setItem('@Nalk:deviceFingerprint', newFingerprint)
  return newFingerprint
}

export { getFingerprint }
