// router.tsx
import React from 'react'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import { CUSTOMER } from '../Config/constants'
import { SignIn } from '../Pages/SignIn'
import { ChatbotPage } from '../Pages/Chatbot'
import { NotFoundPage } from '../Pages/NotFound'
import { FullEmbbedMetaBasePage } from '../Pages/FullEmbbedMetaBase'
import api from '../Services/api'
import { LoginUseCase } from '../Usecase/Auth/login-usecase'
import { AuthenticationApiRemoteService } from '../Services/protocols/service-authentication'
import { CreateThreadUseCase } from '../Usecase/Chat/create-thread-usecase'
import { ChatApiRemoteService } from '../Services/protocols/service-chat'
import { SendMessageUseCase } from '../Usecase/Chat/send-message-usecase'
import { RatingMessageUseCase } from '../Usecase/Chat/rating-message-usecase'
import { SuggestionApiRemoteService } from '../Services/protocols/service-suggestion'
import { ListSuggestionUseCase } from '../Usecase/Chat/list-suggestion-usecase'
import { ListFavoritesUseCase } from '../Usecase/Chat/list-favorites-usecase'
import { FavoritesApiRemoteService } from '../Services/protocols/service-favorites'
import { CreateFavoritesUseCase } from '../Usecase/Chat/create-favorite-usecase'
import { DeleteFavoritesUseCase } from '../Usecase/Chat/delete-favorite-usecase'
import { CreateMetabaseTokenUseCase } from '../Usecase/Auth/create-metabase-token-usecase'
import { CustomFieldsPage } from '../Pages/CustomFields'
import { UpdateCustomFieldsUseCase } from '../Usecase/Ai/update-custom-fields-usecase'
import { CustomFieldsApiRemoteService } from '../Services/protocols/service-custom-fields'
import { ListCustomFieldsUseCase } from '../Usecase/Ai/list-custom-fields-usecase'
import { NewCustomerPage } from '../Pages/Backoffice/NewCustomer'
import { SelectCustomerPage } from '../Pages/Backoffice/SelectCustomer'
import { PanelCustomerPage } from '../Pages/Backoffice/PanelCustomer'
import { ListRolesAndAreasUseCase } from '../Usecase/Companies/list-roles-and-areas-usecase'
import { CompaniesApiRemoteService } from '../Services/protocols/service-companies'
import { TermsOfUsePage } from '../Pages/TermsOfUse'
import { TermsOfPrivacyPage } from '../Pages/TermsOfPrivacy'
import { AcceptTermsOfUseUseCase } from '../Usecase/User/accept-terms-of-use-usecase'
import { UserApiRemoteService } from '../Services/protocols/service-user'
import { AcceptTermsOfPrivacyUseCase } from '../Usecase/User/accept-terms-of-privacy-usecase'
import { CreateCompanyUseCase } from '../Usecase/Companies/create-company-usecase'
import { CompaniesListPage } from '../Pages/Backoffice/CompaniesList'
import { ListCompanyUseCase } from '../Usecase/Companies/list-companies-usecase'
import { ShowCompanyUseCase } from '../Usecase/Companies/show-company-usecase'
import { CreateUserUseCase } from '../Usecase/User/create-user-usecase'
import { DeleteUserUseCase } from '../Usecase/User/delete-user-usecase'
import { UpdateUserUseCase } from '../Usecase/User/update-user-usecase'
import { CreateIntegrationUseCase } from '../Usecase/Integrations/create-integration-usecase'
import { IntegrationApiRemoteService } from '../Services/protocols/service-integration'
import { IntegrationsListPage } from '../Pages/Backoffice/IntegrationsList'
import { ShowIntegrationUseCase } from '../Usecase/Integrations/show-integration-usecase'
import { ChangeCompanyIdUseUseCase } from '../Usecase/User/change-company-id-usecase'

const isAuthenticated = (): any => {
  return localStorage.getItem(`${CUSTOMER}:token`) !== null
}

// Rota privada que requer autenticação
const PrivateRoute = ({ element }: any): JSX.Element => {
  return isAuthenticated()
    ? (
    <Flex
      flexDirection="row"
      width="100%"
      height="100vh"
      sx={{ overflowX: 'hidden !important' }}
    >
      <Flex width="100%" height="100vh">
        {element}
      </Flex>
    </Flex>
      )
    : (
    <Navigate to="/signin" replace />
      )
}

const router = createBrowserRouter([
  {
    path: '/signin',
    element: (
      <SignIn
        loginUseCase={new LoginUseCase(new AuthenticationApiRemoteService(api))}
      />
    )
  },
  {
    path: '/',
    element: <PrivateRoute element={<FullEmbbedMetaBasePage createMetabaseTokenUseCase={new CreateMetabaseTokenUseCase(new AuthenticationApiRemoteService(api))} deleteFavoritesUseCase={new DeleteFavoritesUseCase(new FavoritesApiRemoteService(api))} createFavoritesUseCase={new CreateFavoritesUseCase(new FavoritesApiRemoteService(api))} createThreadUseCase={new CreateThreadUseCase(new ChatApiRemoteService(api))} sendMessageUseCase={new SendMessageUseCase(new ChatApiRemoteService(api))} ratingMessageUseCase={new RatingMessageUseCase(new ChatApiRemoteService(api))} listSuggestionUseCase={new ListSuggestionUseCase(new SuggestionApiRemoteService(api))} listFavoritesUseCase={new ListFavoritesUseCase(new FavoritesApiRemoteService(api))}/> } />
  },
  {
    path: '/ai',
    element: <PrivateRoute element={<ChatbotPage deleteFavoritesUseCase={new DeleteFavoritesUseCase(new FavoritesApiRemoteService(api))} createFavoritesUseCase={new CreateFavoritesUseCase(new FavoritesApiRemoteService(api))} listFavoritesUseCase={new ListFavoritesUseCase(new FavoritesApiRemoteService(api))} createThreadUseCase={new CreateThreadUseCase(new ChatApiRemoteService(api))} sendMessageUseCase={new SendMessageUseCase(new ChatApiRemoteService(api))} ratingMessageUseCase={new RatingMessageUseCase(new ChatApiRemoteService(api))} listSuggestionUseCase={new ListSuggestionUseCase(new SuggestionApiRemoteService(api)) } />} />
  },
  {
    path: '/custom-fields',
    element: <PrivateRoute element={<CustomFieldsPage listCustomFieldsUseCase={new ListCustomFieldsUseCase(new CustomFieldsApiRemoteService(api))} updateCustomFieldsUseCase={ new UpdateCustomFieldsUseCase(new CustomFieldsApiRemoteService(api))} />} />
  },
  {
    path: '/backoffice/new-customer',
    element: <PrivateRoute element={<NewCustomerPage createCompanyUseCase={ new CreateCompanyUseCase(new CompaniesApiRemoteService(api))} />} />
  },
  {
    path: '/backoffice/select-customer',
    element: <PrivateRoute element={<SelectCustomerPage listCompanyUseCase={new ListCompanyUseCase(new CompaniesApiRemoteService(api))} changeCompanyIdUseUseCase={new ChangeCompanyIdUseUseCase(new UserApiRemoteService(api))} />} />
  },
  {
    path: '/backoffice/panel-customer/:id',
    element: <PrivateRoute element={<PanelCustomerPage updateUserUseCase={new UpdateUserUseCase(new UserApiRemoteService(api))} createUserUseCase={new CreateUserUseCase(new UserApiRemoteService(api))} listRolesAndAreasUseCase={new ListRolesAndAreasUseCase(new CompaniesApiRemoteService(api))} showCompanyUseCase={ new ShowCompanyUseCase(new CompaniesApiRemoteService(api))} deleteUserUseCase={new DeleteUserUseCase(new UserApiRemoteService(api))} createIntegrationUseCase={new CreateIntegrationUseCase(new IntegrationApiRemoteService(api))} />} />
  },

  {
    path: '/backoffice/companies',
    element: <PrivateRoute element={<CompaniesListPage listCompanyUseCase={new ListCompanyUseCase(new CompaniesApiRemoteService(api))} />} />
  },
  {
    path: '/backoffice/integrations',
    element: <PrivateRoute element={<IntegrationsListPage listCompanyUseCase={new ListCompanyUseCase(new CompaniesApiRemoteService(api))} showIntegrationUseCase={ new ShowIntegrationUseCase(new IntegrationApiRemoteService(api))} />} />
  },
  {
    path: '/terms-of-use',
    element: <PrivateRoute element={<TermsOfUsePage acceptTermsOfUseUseCase={new AcceptTermsOfUseUseCase(new UserApiRemoteService(api))} />} />
  },
  {
    path: '/terms-of-privacy',
    element: <PrivateRoute element={<TermsOfPrivacyPage acceptTermsOfPrivacyUseCase={new AcceptTermsOfPrivacyUseCase(new UserApiRemoteService(api))} />} />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
])

const Router = (): JSX.Element => {
  return <RouterProvider router={router} />
}

export default Router
