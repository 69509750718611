/* eslint-disable @typescript-eslint/no-floating-promises */
import { Button, Flex, Text, Image, useToast, useTheme, Box } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import Brand from '../../Assets/brand-image.png'
import { useNavigate } from 'react-router-dom'
import { useChat } from '../../Hooks/useChat'
import { LoginUseCase } from '../../Usecase/Auth/login-usecase'
import { useScreenSizes } from '../../utils/screenSizes'
import { ToastCustom } from '../../Components/ToastCustom'
import { getFingerprint } from '../../utils/fingerprint'
import { allowedToChangeCustomer, goToIntegrationsList, goToNewCustomer, goToSelectCustomer } from '../../Components/ModalChoiceAccess'
import { DecodeJwt } from '../../utils/decode-jwt'

interface SignInProps {
  login: string
  password: string
}

const SignInSchemaValidation = Yup.object().shape({
  login: Yup.string().required('Login obrigatório'),
  password: Yup.string().required('Senha obrigatória')
})

interface Props {
  loginUseCase: LoginUseCase
}

const SignIn: FC<Props> = ({ loginUseCase }) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()
  const theme = useTheme()
  const { isMobile } = useScreenSizes()
  const [fingerprint, setFingerPrint] = useState('')

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const { setUserToken, setIsCvc, setModalChoice, setCustomerName, setUserAllowedToChangeCustomer, setCompanyId } = useChat()

  const handleLogin: SubmitHandler<any> = async (values) => {
    try {
      setLoading(true)
      const { username, password } = values

      const loginResult = await loginUseCase.handle({ password, username, macAddress: fingerprint })

      if (loginResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={loginResult.error?.error ?? 'Erro ao logar. Por favor, tente novamente'} />
        })

        return
      }

      const response = loginResult.getValue()

      if (response?.token) {
        setUserToken(response.token)

        const decoded = DecodeJwt(response?.token)
        const email = decoded?.userEmail

        const availablesForModalChoice = [goToNewCustomer, goToIntegrationsList, ...goToSelectCustomer]
        if (availablesForModalChoice.includes(email)) {
          setModalChoice(true)
        } else {
          setModalChoice(false)
        }
      }

      if (response?.email && allowedToChangeCustomer.includes(response.email)) {
        setUserAllowedToChangeCustomer(true)
        setCustomerName(response.customer)
      }

      if (response?.companyId) {
        setCompanyId(response?.companyId)
      }

      if (response?.needsAcceptTermsOfUse) {
        navigate(`/terms-of-use?nextPage=${response?.needsAcceptTermsOfPrivacy ?? false}&toAI=${response?.customer === 'cvc' ?? false}`)
        return
      }

      if (response?.needsAcceptTermsOfPrivacy) {
        navigate('/terms-of-privacy')
        return
      }

      // setNeedsAcceptTermsOfPrivacy(response?.needsAcceptTermsOfPrivacy ?? false)
      // setNeedsAcceptTermsOfUse(response?.needsAcceptTermsOfUse ?? false)

      if (response?.customer === 'cvc') {
        setIsCvc(true)
        return navigate('/ai')
      }

      return isMobile ? navigate('/ai') : navigate('/')
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const load = async (): Promise<void> => {
      const getMacAddress = await getFingerprint()
      console.log('fingerprint: ', getMacAddress)
      setFingerPrint(getMacAddress)
    }

    load()
  }, [])
  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
        <Image src={Brand} alt="Brand" w="124px" h="auto" left={isMobile ? 0 : '100px'} top='33px' position='absolute' />
      <Flex
        as="form"
        w="100%"
        maxWidth='534px'
        height='537px'
        boxShadow='-4px 7px 31px 0px #00000024'
        flexDir="column"
        borderRadius={18}
        onSubmit={handleSubmit(handleLogin)}
        bg="white"

        alignItems='center'
        justifyContent='center'
        margin={0}
      >

          <Text color="gray.900" fontWeight="700" fontSize="36px" margin={0} fontFamily={theme.fonts.bodyBold}>Iniciar Sessão</Text>
          <Box marginTop='35px' w='100%' maxWidth='277px'>
          <Input
            placeholder="digite seu email"
            type="text"
            label="Email"
            labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
            style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
            _placeholder={{ color: 'gray.third' }}
            {...register('username')}
          />
          <Input
            labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
            style={{ borderRadius: 12, borderColor: '#B1C0E0' }}
            _placeholder={{ color: 'gray.third' }}
            placeholder="digite sua senha"
            type="password"
            label="Senha"
            {...register('password')}
          />
          </Box>

        <Button
            type="submit"
            w='278px'
            height='49px'
            borderRadius='40px'
            mt="36px"
            bg="gray.900"
            color="gray.800"
            fontWeight='400'
            isLoading={formState.isSubmitting}
        >
          Entrar
        </Button>
      </Flex>
    </Flex>
  )
}

export { SignIn }
