import styled from '@emotion/styled'

export const TabContainer = styled.div`
  
  width: 100%;
  padding-top: 20px;
`

export const TabContent = styled.div`
  
  flex: 1;
  border-radius: inherit;
`
