/* eslint-disable @typescript-eslint/no-floating-promises */
import { Button, Divider, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useChat } from '../../../Hooks/useChat'
import { DecodeJwt } from '../../../utils/decode-jwt'
import { useNavigate } from 'react-router-dom'
import { ShowIntegrationUseCase } from '../../../Usecase/Integrations/show-integration-usecase'
import { IIntegrationEntity } from '../../../Services/protocols/service-integration'
import { ToastCustom } from '../../ToastCustom'

interface Props {
  onClose: () => void
  companyId: string
  companyName: string
  showIntegrationUseCase: ShowIntegrationUseCase
}

const ModalIntegrationsList: FC<Props> = ({ onClose, companyId, companyName, showIntegrationUseCase }) => {
  const { isNalkEmail, userToken } = useChat()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IIntegrationEntity[]>()
  const navigate = useNavigate()
  const toast = useToast()

  const validateAccess = (): void => {
    const decoded = DecodeJwt(userToken)

    const goToNewCustomer = 'administrativo@nalk.com.br'
    const goToIntegrationsList = 'nicholas@nalk.com.br'

    if (decoded?.userEmail === goToNewCustomer) {
      navigate('/backoffice/new-customer')
      onClose()
      return
    }

    if (decoded?.userEmail === goToIntegrationsList) {
      navigate('/backoffice/integrations')
      onClose()
      return
    }

    if (isNalkEmail(decoded?.userEmail)) {
      navigate('/backoffice/select-customer')
      onClose()
    }
  }

  const loadIntegrations = async (): Promise<void> => {
    try {
      setLoading(true)

      const listResult = await showIntegrationUseCase.handle(companyId)

      if (listResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={listResult.error?.error ?? 'Erro ao pegar as integrações. Por favor, tente novamente'} />
        })

        return
      }

      const response = listResult.getValue()

      if (response?.length) {
        setData(response)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadIntegrations()
  }, [])

  return (
        <Modal isOpen onClose={onClose} size="4xl" >
            <ModalOverlay backgroundColor='rgba(0,0,0,0.8)' />
            <ModalContent>
            <ModalHeader textAlign="center" mt='40px'>Integrações da empresa {companyName}</ModalHeader>

            <ModalBody padding='40px' >
              {data?.length
                ? data?.map(item => (
                <Stack key={item.id} >
                  <Text fontWeight={700} fontSize={19}>Integração</Text>
                  <Text>{item.type}</Text>

                  {item.token && (
                    <Stack mt='20px'>
                        <Text fontWeight={700} fontSize={19}>Token</Text>
                        <Text>{item.token}</Text>
                    </Stack>
                  )}

                  {item.clientId && (
                    <Stack mt='20px'>
                      <Text fontWeight={700} fontSize={19}>Client ID</Text>
                        <Text>{item.clientId}</Text>
                    </Stack>
                  )}

                  {item.clientSecret && (
                    <Stack mt='20px'>
                       <Text fontWeight={700} fontSize={19}>Client Secret</Text>
                        <Text>{item.clientSecret}</Text>
                    </Stack>
                  )}

                  {item.refreshToken && (
                    <Stack mt='20px'>
                      <Text fontWeight={700} fontSize={19}>Refresh Token</Text>
                        <Text>{item.refreshToken}</Text>
                    </Stack>
                  )}

                  {item.login && (
                    <Stack mt='20px'>
                      <Text fontWeight={700} fontSize={19}>Login</Text>
                        <Text>{item.login}</Text>
                    </Stack>
                  )}

                  {item.password && (
                    <Stack mt='20px'>
                        <Text fontWeight={700} fontSize={19}>Password</Text>
                        <Text>{item.password}</Text>
                    </Stack>
                  )}

                  <Divider mt='40px' />
                </Stack>
                ))
                : <Flex justifyContent='center' w='100%'><Text mt='30px' mb='30px'>Nenhuma integração encontrada para este cliente.</Text></Flex>}

                {/* <ModalFooter>
                  <Button onClick={onClose}>Voltar</Button>
                </ModalFooter> */}

            </ModalBody>
            </ModalContent>

        </Modal>
  )
}

export { ModalIntegrationsList }
