/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Stack, theme, useTheme, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { IUserEntity } from '../../../Services/protocols/service-user'
import { CreateUserUseCase } from '../../../Usecase/User/create-user-usecase'
import { UpdateUserUseCase } from '../../../Usecase/User/update-user-usecase'
import { Input } from '../../Form/Input'
import { OptionsProps, Select } from '../../Form/Select'
import { ToastCustom } from '../../ToastCustom'
import { CreateIntegrationUseCase } from '../../../Usecase/Integrations/create-integration-usecase'
import { TCreateIntegrationsPayload } from '../../../Services/protocols/service-integration'

interface IModalNewIntegrationProps {
  onClose: () => void
  onSubmit: () => void
  user?: IUserEntity
  roles: OptionsProps[]
  areas: OptionsProps[]
  createIntegrationUseCase: CreateIntegrationUseCase
  updateUserUseCase: UpdateUserUseCase
  companyId: string
  cbFunction: () => void
}

const ModalNewIntegration: FC<IModalNewIntegrationProps> = ({ onClose, roles, areas, createIntegrationUseCase, companyId, cbFunction, user, updateUserUseCase }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [integrationType, setIntegrationType] = useState<'token' | 'client_secret' | undefined>(undefined)

  const integrationsOptions = [
    {
      label: 'Hubspot',
      value: 'Hubspot'
    },
    {
      label: 'Pipedrive',
      value: 'Pipedrive'
    },
    {
      label: 'Active Campaign',
      value: 'ACTIVE_CAMPAIGN'
    },
    {
      label: 'Zoho',
      value: 'ZOHO'
    }

  ]

  const { handleSubmit, register, formState, watch, unregister, resetField } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const integrationValue = watch('type')

  const handleIntegration: SubmitHandler<any> = async (values) => {
    try {
      setLoading(true)
      console.log('values: ', values)

      const payload: TCreateIntegrationsPayload = {
        companyId,
        ...values,
        type: String(values?.type)?.toUpperCase()

      }

      console.log('payload: ', payload)

      const createResult = await createIntegrationUseCase.handle(payload)

      if (createResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={createResult.error?.error ?? 'Erro ao criar integração. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Integração Cadastrada' />
      })

      cbFunction()

      // if (user?.id) {
      //   // update
      //   const payload: TUpdateUserPayload = {
      //     companyId,
      //     collaborators: [
      //       {
      //         userId: user.id,
      //         firstName: values?.name,
      //         lastName: values?.surname,
      //         email: values?.email,
      //         groups: getPermissions(values),
      //         companyRoleId: values?.position,
      //         companyAreaId: values?.area
      //       }
      //     ]

      //   }

      //   console.log('payload: ', payload)

      //   const updateResult = await updateUserUseCase.handle(payload)

      //   if (updateResult.isFailure) {
      //     toast({
      //       position: 'top-right',
      //       render: () => <ToastCustom type='fail' title='Erro' description={updateResult.error?.error ?? 'Erro ao editar usuário. Por favor, tente novamente'} />
      //     })

      //     return
      //   }

      //   toast({
      //     position: 'top-right',
      //     render: () => <ToastCustom type='success' title='Sucesso' description='Usuário Atualizado' />
      //   })

      //   cbFunction()
      // } else {
      //   const payload: TCreateUserPayload = {
      //     companyId,
      //     collaborator: {
      //       firstName: values?.name,
      //       lastName: values?.surname,
      //       email: values?.email,
      //       groups: getPermissions(values),
      //       userRoleId: values?.position,
      //       userAreaId: values?.area
      //     }

      //   }

      //   console.log('payload: ', payload)

      //   const createResult = await createUserUseCase.handle(payload)

      //   if (createResult.isFailure) {
      //     toast({
      //       position: 'top-right',
      //       render: () => <ToastCustom type='fail' title='Erro' description={createResult.error?.error ?? 'Erro ao criar usuário. Por favor, tente novamente'} />
      //     })

      //     return
      //   }

      //   toast({
      //     position: 'top-right',
      //     render: () => <ToastCustom type='success' title='Sucesso' description='Usuário Cadastrado' />
      //   })

      //   cbFunction()
      // }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const handleIntegrationsFields = (integrationValue: any): void => {
    const tokenTypes = ['HUBSPOT', 'PIPEDRIVE', 'ACTIVE_CAMPAIGN']
    const secretTypes = ['ZOHO']

    if (!integrationValue) return

    resetField('token')

    if (tokenTypes.includes(integrationValue.toUpperCase())) {
      setIntegrationType('token')
      unregister('clientId')
      unregister('clientSecret')
      unregister('refreshToken')
    }

    if (secretTypes.includes(integrationValue.toUpperCase())) {
      setIntegrationType('client_secret')
      unregister('token')
    }
  }

  useEffect(() => {
    handleIntegrationsFields(integrationValue)
  }, [integrationValue])

  return (
        <Modal isOpen onClose={onClose} isCentered size='6xl'>
          <ModalOverlay />
          <ModalContent shadow='box-shadow: 0px 0px 12px 0px #00000026' bgColor={theme.colors.white.primary} border='none' borderRadius='16px'>

          <ModalBody padding="48px">
          <ModalCloseButton w='15px' h='15px' marginTop='5px' marginRight='3px' />
          <Flex
            as="form"

            flexDir="column"
            onSubmit={handleSubmit(handleIntegration)}
            bg="white"

        >
          <Stack gap='12px' padding='0px' borderRadius='10px'>
              <Flex gap='12px'>
                    <Select
                      label='Integração'
                      placeholder='Selecione uma opção'
                      options={integrationsOptions}
                      defaultValue={user?.companyRole?.id}

                      {...register('type')}
                      />
              </Flex>

              {integrationType === 'token' && (
                   <Flex gap='12px'>
                          <Input
                          type="text"
                          label="Token de acesso"
                          placeholder='Preencher o token'
                          labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                          style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                          _placeholder={{ color: 'gray.third' }}
                          defaultValue={user?.metabaseUser?.firstName}
                          {...register('token')}
                          />
                   </Flex>
              )}

              {integrationType === 'client_secret' && (
                   <Flex gap='12px'>
                        <Input
                          type="text"
                          label="Client ID"
                          placeholder='Preencher'
                          labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                          style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                          _placeholder={{ color: 'gray.third' }}
                          // defaultValue={user?.metabaseUser?.firstName}
                          {...register('clientId')}
                        />

                        <Input
                          type="text"
                          label="Client Secret"
                          placeholder='Preencher '
                          labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                          style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                          _placeholder={{ color: 'gray.third' }}
                          // defaultValue={user?.metabaseUser?.lastName}
                          {...register('clientSecret')}
                        />

                        <Input
                          type="text"
                          label="Refresh Token"
                          placeholder='Preencher'
                          labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                          style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                          _placeholder={{ color: 'gray.third' }}
                          // defaultValue={user?.metabaseUser?.email}
                          {...register('refreshToken')}
                        />

                </Flex>
              )}

          </Stack>

            <Flex justifyContent='flex-end' w='100%' padding='0px' marginTop='auto'>
              <Button
                  type="submit"
                  w='278px'
                  height='49px'
                  borderRadius='40px'
                  mt="50px"
                  bg="gray.900"
                  color="gray.800"
                  fontWeight='400'
                  isLoading={formState.isSubmitting}
              >
              Salvar
              </Button>
            </Flex>
          </Flex>

          </ModalBody>

          </ModalContent>
      </Modal>
  )
}

export { ModalNewIntegration }
