/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Button, Stack, Text, Flex, useToast } from '@chakra-ui/react'
import { Header } from '../../Components/Header'
import { AcceptTermsOfUseUseCase } from '../../Usecase/User/accept-terms-of-use-usecase'
import { getFingerprint } from '../../utils/fingerprint'
import { ToastCustom } from '../../Components/ToastCustom'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface ITermsOfUsePageProps {
  acceptTermsOfUseUseCase: AcceptTermsOfUseUseCase
}
const TermsOfUsePage: FC<ITermsOfUsePageProps> = ({ acceptTermsOfUseUseCase }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const nextPage = searchParams.get('nextPage')
  const toAI = searchParams.get('toAI')
  console.log('nextPage: ', typeof nextPage)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked)
  }

  if (!isMounted) return null

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      const macAddress = await getFingerprint()

      const acceptResult = await acceptTermsOfUseUseCase.handle(macAddress)

      if (acceptResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={acceptResult.error?.error ?? 'Erro. Por favor, tente novamente'} />
        })

        return
      }

      if (nextPage === 'true') {
        navigate(`/terms-of-privacy?toAI=${String(toAI)}`)
      } else {
        if (toAI) {
          navigate('/ai')
        } else {
          navigate('/')
        }
      }

      // if()
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Header hideSidebar />
      <Stack w='100%' padding='20px'>

      <Text>Termos de Uso</Text>

        <Text mb={4}>Por favor, leia os termos de uso abaixo:</Text>
        <embed
          src="/Files/termos-de-uso.pdf"
          type="application/pdf"
          width="100%"
          height="750px"
        />
        <Checkbox mt={4} onChange={handleCheckboxChange}>
          Eu aceito os termos de uso
        </Checkbox>

        <Flex justifyContent='flex-end' padding='10px'>
        <Button colorScheme="blue" onClick={handleSubmit} isDisabled={!isChecked}>
          Salvar
        </Button>
        </Flex>

        </Stack>
    </>

  )
}

export { TermsOfUsePage }
