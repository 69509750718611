import React, { useState, useEffect, FC } from 'react'
import {
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Button
} from '@chakra-ui/react'

interface IPrivacyModalProps {
  handleSubmit: (data?: any) => void
}

const PrivacyModal: FC<IPrivacyModalProps> = ({ handleSubmit }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked)
  }

  if (!isMounted) return null

  return (
    <Modal isOpen={true} onClose={() => {}} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Aviso de Privacidade</ModalHeader>
        <ModalBody>
          <Text mb={4}>Por favor, leia o Aviso de Privacidade:</Text>
          <embed
            src="/Files/privacidade.pdf"
            type="application/pdf"
            width="100%"
            height="750px"
          />
          <Checkbox mt={4} onChange={handleCheckboxChange}>
            Eu aceito o Aviso de Privacidade
          </Checkbox>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit} isDisabled={!isChecked}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PrivacyModal
