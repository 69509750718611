import { Text, Box } from '@chakra-ui/react'
import React, { FC, ReactNode } from 'react'
import { validateUserPermissions } from '../../utils/validateUserPermissions'
import { useChat } from '../../Hooks/useChat'
import { DecodeJwt } from '../../utils/decode-jwt'
import { useLocation } from 'react-router-dom'
import { goToIntegrationsList, goToNewCustomer, goToSelectCustomer } from '../ModalChoiceAccess'

interface Props {
  children: ReactNode
  permissions?: string[]
  roles?: string[]
  page?: boolean
}
const Can: FC<Props> = ({ children, permissions, roles, page = false }) => {
  // const { user } = useAuth()
  const { userToken } = useChat()
  const location = useLocation()

  const decoded = DecodeJwt(userToken)
  const email = decoded?.userEmail

  const hasPermission = (): boolean => {
    let permission = true

    if (location.pathname === '/backoffice/new-customer' && email !== goToNewCustomer) {
      permission = false
    }

    if (location.pathname === '/backoffice/select-customer' && !goToSelectCustomer.includes(email)) {
      permission = false
    }

    if (location.pathname === '/backoffice/panel-customer/:id' && !goToSelectCustomer.includes(email)) {
      permission = false
    }

    if (location.pathname === '/backoffice/companies' && email !== goToNewCustomer) {
      permission = false
    }

    if (location.pathname === '/backoffice/integrations' && email !== goToIntegrationsList) {
      permission = false
    }

    return permission
  }

  // const hasPermission = validateUserPermissions({ user, permissions, roles })
  if (!hasPermission()) {
    if (page) {
      return <Box w="100vw" h="100vh" alignItems="center" justifyContent="center" display="flex"><Text color="black" p={10}>Permissão negada.</Text></Box>
    }

    return <Box />
  }
  return <>{children}</>
}

export { Can }
